var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "informationArea" },
    [
      _vm.showPopoverForGroupOwner
        ? _c(
            "CommonPopover",
            {
              staticClass: "commonPopoverStyle",
              attrs: { operatingMenu: _vm.operatingMenu },
              on: { selectThisMenuItem: _vm.selectThisMenuItem }
            },
            [
              _c("div", { staticClass: "commonPopoverButton" }, [
                _c("img", {
                  style: { width: "16px", objectFit: "contain" },
                  attrs: {
                    src: require("../../../assets/images/newPersonalCenter/openPopoverIcon.png")
                  }
                })
              ])
            ]
          )
        : _vm._e(),
      _vm.complaintOperatingMenu.length > 0 && !_vm.showPopoverForGroupOwner
        ? _c(
            "CommonPopover",
            {
              staticClass: "commonPopoverStyle",
              attrs: { operatingMenu: _vm.complaintOperatingMenu },
              on: { selectThisMenuItem: _vm.selectComplaintMenuItem }
            },
            [
              _c("div", { staticClass: "commonPopoverButton" }, [
                _c("img", {
                  style: { width: "16px", objectFit: "contain" },
                  attrs: {
                    src: require("../../../assets/images/newPersonalCenter/openPopoverIcon.png")
                  }
                })
              ])
            ]
          )
        : _vm._e(),
      _c("GroupBusinessCard", {
        staticClass: "groupCard",
        attrs: {
          groupBusinessCardInfo: _vm.groupBusinessCardInfo,
          searchPageKey: _vm.searchPageKey
        }
      }),
      _vm.showFooter
        ? _c("InformationFooterArea", {
            staticClass: "areaFooter",
            attrs: { footerInfo: _vm.footerInfo }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }