var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "postInformationItem" },
    [
      _vm.operatingMenu.length !== 0
        ? _c(
            "CommonPopover",
            {
              staticClass: "commonPopoverStyle",
              attrs: { operatingMenu: _vm.operatingMenu },
              on: { selectThisMenuItem: _vm.selectThisMenuItem }
            },
            [
              _c("div", { staticClass: "commonPopoverButton" }, [
                _c("img", {
                  style: { width: "16px", objectFit: "contain" },
                  attrs: {
                    src: require("../../../assets/images/newPersonalCenter/openPopoverIcon.png")
                  }
                })
              ])
            ]
          )
        : _vm._e(),
      _c("PostHeader", {
        attrs: {
          headerData: _vm.headerData,
          vipStatus: _vm.postInfo.vipStatus,
          showViewCount: false
        }
      }),
      _c(
        "div",
        { staticClass: "postContent" },
        [
          _c(
            "div",
            { on: { click: _vm.jumpToPage } },
            [
              _vm.postInfo.iCanDo && _vm.postInfo.iCanDo !== ""
                ? _c("h3", {
                    staticClass: "postTitleContainer",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.highlightText(
                          _vm.searchPageKey,
                          _vm.postInfo.iCanDo
                        )
                      )
                    }
                  })
                : _vm._e(),
              _c("TextDisplay", {
                attrs: {
                  postDetail: _vm.postInfo.detail,
                  searchPageKey: _vm.searchPageKey
                },
                on: {
                  resetLayout: function($event) {
                    return _vm.$emit("resetLayout")
                  },
                  jumpToPage: _vm.jumpToPage
                }
              }),
              _vm.postInfo.documentType !== 0
                ? _c(
                    "div",
                    { staticClass: "attachmentsContainer" },
                    [
                      _vm.postInfo.documentType === 1 &&
                      _vm.videoConfig.path !== ""
                        ? _c("VideoPreview", {
                            attrs: {
                              isFromPostList: true,
                              config: _vm.videoConfig
                            },
                            on: { videoLoadSuccess: _vm.videoLoadSuccess }
                          })
                        : _vm._e(),
                      _vm.postInfo.documentType === 3 &&
                      _vm.pictures.length !== 0
                        ? _c("PicturePreview", {
                            attrs: { pictures: _vm.pictures, gigId: _vm.gigId },
                            on: {
                              imageLoadSuccess: _vm.imageLoadSuccess,
                              addViewCount: _vm.addViewCount
                            }
                          })
                        : _vm._e(),
                      _vm.postInfo.documentType === 4 &&
                      _vm.fileInfo.path !== ""
                        ? _c("FilePreview", {
                            attrs: { fileInfo: _vm.fileInfo }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tags.length !== 0
                ? _c(
                    "div",
                    { staticClass: "labelContainer" },
                    _vm._l(_vm.tags, function(item, index) {
                      return _c("Label", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item != "",
                            expression: "item != ''"
                          }
                        ],
                        key: index + item,
                        attrs: {
                          labelContent: item,
                          hasHoverStyle: true,
                          searchPageKey: _vm.searchPageKey
                        },
                        on: { selectThisLabel: _vm.selectThisLabel }
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c("PostFooter", {
                staticClass: "postFooter",
                attrs: { footerInfo: _vm.footerInfo, showCommentIcon: true },
                on: {
                  startComment: _vm.startComment,
                  changelikeStatus: _vm.changelikeStatus
                }
              })
            ],
            1
          ),
          _vm.reviewDetailVos
            ? _c("NewCommentBlock", {
                attrs: {
                  comment: _vm.reviewDetailVos,
                  maxLine: 2,
                  popover: false,
                  showInput: true,
                  getCommentsParam: _vm.getCommentsParam
                },
                on: {
                  clickCommentBlock: _vm.clickCommentBlock,
                  resetLayout: function($event) {
                    return _vm.$emit("resetLayout")
                  }
                }
              })
            : _vm._e(),
          _vm.displayTheInputBox
            ? _c("CommentInputBox", {
                staticClass: "commentInputBox",
                attrs: {
                  showAvatar: true,
                  autoHeight: false,
                  sendLoading: _vm.sendLoading,
                  withPictures: true,
                  answerSomeone: _vm.reviewDetailVos
                    ? _vm.reviewDetailVos.reviewer
                    : ""
                },
                on: {
                  triggerSubmit: _vm.triggerSubmit,
                  resetLayout: function($event) {
                    return _vm.$emit("resetLayout")
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }