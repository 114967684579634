<template>
  <div class="postInformationItem">
    <CommonPopover
      class="commonPopoverStyle"
      v-if="operatingMenu.length !== 0"
      :operatingMenu="operatingMenu"
      @selectThisMenuItem="selectThisMenuItem"
    >
      <div class="commonPopoverButton">
        <img
          :style="{ width: '16px', objectFit: 'contain' }"
          :src="
            require('../../../assets/images/newPersonalCenter/openPopoverIcon.png')
          "
        />
      </div>
    </CommonPopover>
    <PostHeader
      :headerData="headerData"
      :vipStatus="postInfo.vipStatus"
      :showViewCount="false"
    ></PostHeader>
    <div class="postContent">
      <div @click="jumpToPage">
        <h3
          class="postTitleContainer"
          v-if="postInfo.iCanDo && postInfo.iCanDo !== ''"
          v-html="highlightText(searchPageKey, postInfo.iCanDo)"
        ></h3>
        <TextDisplay
          :postDetail="postInfo.detail"
          @resetLayout="$emit('resetLayout')"
          @jumpToPage="jumpToPage"
          :searchPageKey="searchPageKey"
        >
        </TextDisplay>
        <div class="attachmentsContainer" v-if="postInfo.documentType !== 0">
          <VideoPreview
            v-if="postInfo.documentType === 1 && videoConfig.path !== ''"
            :isFromPostList="true"
            :config="videoConfig"
            @videoLoadSuccess="videoLoadSuccess"
          ></VideoPreview>
          <PicturePreview
            v-if="postInfo.documentType === 3 && pictures.length !== 0"
            :pictures="pictures"
            @imageLoadSuccess="imageLoadSuccess"
            :gigId="gigId"
            @addViewCount="addViewCount"
          ></PicturePreview>
          <FilePreview
            v-if="postInfo.documentType === 4 && fileInfo.path !== ''"
            :fileInfo="fileInfo"
          ></FilePreview>
        </div>
        <div class="labelContainer" v-if="tags.length !== 0">
          <Label
            v-for="(item, index) in tags"
            :key="index + item"
            :labelContent="item"
            v-show="item != ''"
            :hasHoverStyle="true"
            @selectThisLabel="selectThisLabel"
            :searchPageKey="searchPageKey"
          ></Label>
        </div>
        <PostFooter
          class="postFooter"
          :footerInfo="footerInfo"
          :showCommentIcon="true"
          @startComment="startComment"
          @changelikeStatus="changelikeStatus"
        ></PostFooter>
      </div>
      <NewCommentBlock
        v-if="reviewDetailVos"
        :comment="reviewDetailVos"
        :maxLine="2"
        :popover="false"
        :showInput="true"
        :getCommentsParam="getCommentsParam"
        @clickCommentBlock="clickCommentBlock"
        @resetLayout="$emit('resetLayout')"
      ></NewCommentBlock>
      <CommentInputBox
        class="commentInputBox"
        v-if="displayTheInputBox"
        :showAvatar="true"
        @triggerSubmit="triggerSubmit"
        :autoHeight="false"
        :sendLoading="sendLoading"
        @resetLayout="$emit('resetLayout')"
        :withPictures="true"
        :answerSomeone="reviewDetailVos ? reviewDetailVos.reviewer : ''"
      ></CommentInputBox>
    </div>
  </div>
</template>
<script>
import Label from "../label";
import PostHeader from "../postHeader";
import PostFooter from "../postFooter";
import TextDisplay from "../textDisplay";
import PicturePreview from "../picturePreview/basis";
import VideoPreview from "../videoPlayer/smallVersion";
import FilePreview from "../showFileItem";
import CommentInputBox from "../comment/commentInputBox";
import { addNewReview } from "@/api/newVersion";
import CommonPopover from "../commonPopover";
import NewCommentBlock from "../comment/newComment.vue";
import { getFatherComments } from "@/api/newVersion/postContentDetails";
export default {
  components: {
    Label,
    PostHeader,
    PostFooter,
    TextDisplay,
    PicturePreview,
    VideoPreview,
    FilePreview,
    NewCommentBlock,
    CommentInputBox,
    CommonPopover,
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    operatingMenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fromType: {
      type: String,
      default: "home",
    },
    // 用于高亮展示
    searchPageKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 展示评论输入框
      displayTheInputBox: false,
      // 是否是在回复已有评论
      inReplyComment: false,
      sendLoading: false,
    };
  },
  watch: {
    // 监听输入框打开状态
    displayTheInputBox: {
      handler(val, old) {
        if (val && val !== old) {
          this.$store.commit(
            "setCommentInputIsOpen",
            `commentInput*${this.postInfo.gigId}`
          );
        }
      },
      deep: true,
    },
    // 监听首页全局输入框是否只有自己打开，如果开启了其他输入框，则关闭自己
    "$store.state.homePage.commentInputIsOpen": {
      handler() {
        if (
          this.$store.state.homePage.commentInputIsOpen !==
          `commentInput*${this.postInfo.gigId}`
        ) {
          this.displayTheInputBox = false;
          this.$nextTick(() => {
            this.$emit("resetLayout");
          });
        }
      },
      deep: true,
    },
    // 删除后刷新评论
    "$store.state.deleteReason.deleteReviewId": {
      async handler(val, old) {
        if (this.reviewDetailVos && old == this.reviewDetailVos.reviewId) {
          await this.getPostNewComment(this.postInfo.gigId);
          if (Number(this.postInfo.commentCount) > 0) {
            this.$set(
              this.postInfo,
              "commentCount",
              Number(this.postInfo.commentCount) - 1
            );
          }
        }
      },
      deep: true,
    },
    // 监听已被添加新评论的帖子队列变化
    "$store.state.globalShare.beCommentedGigids": {
      async handler(val, old) {
        const index = val.indexOf(this.postInfo.gigId);
        if (val.length !== 0 && index !== -1) {
          try {
            await this.getPostNewComment(this.postInfo.gigId);
            this.$emit("resetLayout");
          } catch (error) {}
          const beCommentedGigids = val;
          beCommentedGigids.splice(index, 1);
          this.$store.commit("setBeCommentedGigids", [
            ...new Set(beCommentedGigids),
          ]);
        }
      },
      deep: true,
    },
  },
  computed: {
    // 获取评论列表数据的参数
    getCommentsParam() {
      return {
        // 操作对象ID
        id: this.postInfo.gigId,
        // 操作对象(1:旧版帖子评论,3:用户,4:群,5:公司,7:新版帖子评论,8：新版个人中心评论)
        operation: 7,
        // 点赞用的type(1:帖子,3:用户,4:群)
        type: 1,
        // 这个参数表示更多评论是否用弹窗展示
        popupDisplay: true,
      };
    },
    // 帖子底部信息
    footerInfo() {
      return {
        pageViewCount: this.postInfo.pageViewCount,
        commentCount: this.postInfo.commentCount,
        likeCount: this.postInfo.likeCount,
        likeStatus: this.postInfo.likeStatus,
        gigId: this.postInfo.gigId,
        postInfo: this.postInfo,
        type: 1,
      };
    },
    // 帖子标签
    tags() {
      let tags = [];
      if (this.postInfo.tags && this.postInfo.tags !== "") {
        tags = this.postInfo.tags.split(",");
      }
      return tags;
    },
    // 帖子ID
    gigId() {
      return this.postInfo.gigId;
    },
    // 帖子视频附件
    videoConfig() {
      let videoConfig = {
        // 视频地址
        path: "",
        // 播放器大小设置（samll为上传视频的大小;exquisite为主页贴子展示的视频）
        type: "small",
        // 视频播放进度（秒）
        currentTime: 0,
        // 当前是否是悬浮播放
        isFloating: false,
        // 是否显示浮窗控制按钮
        floatingWindowPlay: true,
        // 是否展示放大按钮
        zoomControl: true,
        // 是否展示下载按钮
        downloadControl: true,
        // 音量控制按钮是否展示
        volumeControl: true,
        // 从列表来的视频
        isFromTheList: true,
        // 帖子ID
        gigId: "",
      };
      // 为了兼容老帖子，需要遍历将附件类型中的视频文件找出来(老数据可能会有除了视频以外的其他数据，所以必须过滤一次)
      if (
        this.postInfo.documentType === 1 &&
        this.postInfo.attachments.length !== 0
      ) {
        videoConfig.gigId = this.gigId;
        let videoItems = this.postInfo.attachments.filter((item) => {
          return this.matchFileSuffixType(item.path) === "video";
        });
        if (videoItems && videoItems.length !== 0) {
          videoConfig.path = videoItems[0].path;
        } else {
          console.error(
            "视频帖子数据错误，未检测到有视频文件，请检查后台数据是否有误",
            this.gigId
          );
        }
      } else {
        console.error(
          "视频帖子数据错误，文件列表为空，请检查后台数据是否有误",
          this.gigId
        );
      }
      return videoConfig;
    },
    // 帖子图片附件
    pictures() {
      let pictures = [];
      // （1：视频 2：音频，3：图片， 4文件
      // 为了兼容老帖子，需要遍历将附件类型中的图片文件找出来
      if (
        this.postInfo.documentType === 3 &&
        this.postInfo.attachments.length !== 0
      ) {
        let imageItems = this.postInfo.attachments.filter((item) => {
          return this.matchFileSuffixType(item.path) === "image";
        });
        if (imageItems && imageItems.length !== 0) {
          imageItems.forEach((e) => {
            pictures.push(e.path);
          });
        } else {
          console.error(
            "图片帖子数据错误，未检测到有图片文件，请检查后台数据是否有误",
            this.gigId
          );
        }
      } else {
        console.error(
          "图片帖子数据错误，文件列表为空，请检查后台数据是否有误",
          this.gigId
        );
      }
      return pictures;
    },
    // 帖子文件附件
    fileInfo() {
      let fileInfo = {
        progress: "100.00",
        name: "",
        path: "",
      };
      if (
        this.postInfo.documentType === 4 &&
        this.postInfo.attachments.length !== 0
      ) {
        let fileItems = this.postInfo.attachments.filter((item) => {
          return (
            this.matchFileSuffixType(item.path) !== "video" &&
            this.matchFileSuffixType(item.path) !== "image"
          );
        });
        if (fileItems && fileItems.length !== 0) {
          fileInfo.name = fileItems[0].name;
          fileInfo.path = fileItems[0].path;
        } else {
          console.error(
            "文件帖子数据错误，未检测到有非视频或非图片的文件，请检查后台数据是否有误",
            this.gigId
          );
        }
      } else {
        console.error(
          "文件帖子数据错误，文件列表为空，请检查后台数据是否有误",
          this.gigId
        );
      }
      return fileInfo;
    },
    // 头部信息数据
    headerData() {
      return {
        name: this.postInfo.name,
        country: this.postInfo.country != "" ? this.postInfo.country : 0,
        profilePhoto: this.postInfo.profilePhoto,
        providerId: this.postInfo.providerId,
        publishTime: this.postInfo.publishTime || this.postInfo.createTime,
        pageViewCount: this.postInfo.pageViewCount,
      };
    },
    // 最新评论
    reviewDetailVos() {
      return this.postInfo.reviewDetailVos &&
        this.postInfo.reviewDetailVos.length !== 0
        ? this.postInfo.reviewDetailVos[0]
        : null;
    },
  },
  methods: {
    // 点击评论模块
    clickCommentBlock() {
      this.addViewCount();
      this.$store.commit("setShowCommentInputInPostInfo", true);
      this.routeJump({
        name: "postContentDetails",
        params: {
          id: this.postInfo.gigId,
          comment: true,
        },
      });
    },
    addViewCount() {
      this.postInfo.pageViewCount = Number(this.postInfo.pageViewCount) + 1;
    },
    // 跳转详情
    jumpToPage(event) {
      this.$store.commit("setShowCommentInputInPostInfo", false);
      this.addViewCount();
      this.routeJump({
        name: "postContentDetails",
        params: { id: this.postInfo.gigId },
      });
    },
    // 选择这个标签作为搜索条件
    selectThisLabel(searchKey) {
      this.$store.commit("setSearchPageKey", searchKey);
      // 每次进入搜索页面都将搜索页面展示tab下标重置
      this.$store.commit("setSearchTabIndex", 0);
      this.routeJump({
        name: "newSearch",
        params: { isInterestedSearch: "0" },
      });
    },
    // 改变喜欢状态
    changelikeStatus() {
      this.postInfo.likeStatus = !this.postInfo.likeStatus;
      this.postInfo.likeStatus
        ? this.postInfo.likeCount++
        : this.postInfo.likeCount--;
      // 将点赞结果存入状态管理器中
      let likedRecord = {
        likeItemId: this.postInfo.gigId,
        likeCount: this.postInfo.likeCount,
        likeStatus: this.postInfo.likeStatus,
      };
      this.$store.dispatch("commitSetAlreadyLikedRecords", likedRecord);
      this.$forceUpdate();
    },
    // 开始评论这条帖子
    startComment(inReplyComment = false) {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.inReplyComment = inReplyComment;
      this.displayTheInputBox = !this.displayTheInputBox;
      this.$emit("resetLayout");
    },
    // 提交评论
    async triggerSubmit(param) {
      this.sendLoading = true;
      let params = {};
      if (!this.inReplyComment) {
        params = {
          operation: 7,
          reply: param.commentText,
        };
      } else {
        const comment = this.reviewDetailVos;
        params = {
          operation: 7,
          reply: param.commentText,
          reviewId: comment.reviewId,
        };
      }
      if (param.imgUrl) {
        params.imgUrl = param.imgUrl;
      }
      let result = await addNewReview(this.postInfo.gigId, params);
      this.sendLoading = false;
      if (result.code === 200) {
        this.$nextTick(async () => {
          this.displayTheInputBox = !this.displayTheInputBox;
          if (
            !this.postInfo.commentCount ||
            Number(this.postInfo.commentCount) == 0
          ) {
            this.$set(this.postInfo, "commentCount", 1);
          } else {
            this.$set(
              this.postInfo,
              "commentCount",
              Number(this.postInfo.commentCount) + 1
            );
          }
          try {
            // 当是主页帖子时才刷新最新评论
            if (this.fromType == "home") {
              await this.getPostNewComment(this.postInfo.gigId);
            }
          } catch (error) {}
          this.$emit("resetLayout");
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 获取帖子最新的评论
    async getPostNewComment(gigId) {
      let params = {
        operation: 7,
        showSize: 1,
      };
      let result = await getFatherComments(gigId, params);
      if (result.code === 200) {
        this.$set(this.postInfo, "reviewDetailVos", result.data.data);
        this.$set(this.postInfo, "commentCount", result.data.total);
        this.$nextTick(() => {
          this.$emit("resetLayout");
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 附件图片加载成功
    imageLoadSuccess() {
      this.$emit("resetLayout");
    },
    // 附件视频加载成功
    videoLoadSuccess() {
      this.$emit("resetLayout");
    },
    // 选择操作菜单的选项
    selectThisMenuItem(index) {
      if (this.operatingMenu.length !== 0) {
        this.operatingMenu[index].operatingFn(this.postInfo);
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.postInformationItem
  width 100%;
  min-height 0px;
  padding 24px 30px;
  box-sizing border-box;
  overflow hidden;
  cursor pointer;
  position relative;
  .commonPopoverStyle
    position absolute;
    top 20px;
    right 30px;
    z-index 2;
    .commonPopoverButton
      width 24px;
      height 20px;
      cursor pointer;
      border-radius 4px;
      background #FFFFFF;
      display flex;
      align-items center;
      justify-content center;
  .postContent
    width 100%;
    min-height 0;
    overflow hidden;
    padding-left 64px;
    box-sizing border-box;
    .postTitleContainer
      font-size 16px;
      color #373737;
      margin 12px 0;
      overflow hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    .richDetailContainer
      width 100%;
      min-height 0;
      max-height 120px;
      line-height 24px;
      font-size 14px;
      overflow hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      box-sizing border-box;
    .attachmentsContainer
      width 100%;
      min-height 0;
      overflow hidden;
      box-sizing border-box;
      margin-top 8px;
    .labelContainer
      width 100%;
      max-height 68px;
      margin-top 10px;
      overflow hidden;
      line-height: 34px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    .postFooter
      width 100%;
      margin-top 15px;
    .commentInputBox
      width 100%;
      margin-top 20px;
</style>
