<template>
  <div class="informationArea">
    <CommonPopover
      class="commonPopoverStyle"
      v-if="showPopoverForGroupOwner"
      :operatingMenu="operatingMenu"
      @selectThisMenuItem="selectThisMenuItem"
    >
      <div class="commonPopoverButton">
        <img
          :style="{ width: '16px', objectFit: 'contain' }"
          :src="
            require('../../../assets/images/newPersonalCenter/openPopoverIcon.png')
          "
        />
      </div>
    </CommonPopover>
    <!-- 投诉操作 -->
    <CommonPopover
      v-if="complaintOperatingMenu.length > 0 && !showPopoverForGroupOwner"
      class="commonPopoverStyle"
      :operatingMenu="complaintOperatingMenu"
      @selectThisMenuItem="selectComplaintMenuItem"
    >
      <div class="commonPopoverButton">
        <img
          :style="{ width: '16px', objectFit: 'contain' }"
          :src="
            require('../../../assets/images/newPersonalCenter/openPopoverIcon.png')
          "
        />
      </div>
    </CommonPopover>
    <GroupBusinessCard
      class="groupCard"
      :groupBusinessCardInfo="groupBusinessCardInfo"
      :searchPageKey="searchPageKey"
    ></GroupBusinessCard>
    <InformationFooterArea
      v-if="showFooter"
      class="areaFooter"
      :footerInfo="footerInfo"
    ></InformationFooterArea>
  </div>
</template>
<script>
import InformationFooterArea from "../homeDisplayComponents/informationFooterArea";
import GroupBusinessCard from "../groupBusinessCard";
import CommonPopover from "../commonPopover";
export default {
  components: {
    InformationFooterArea,
    GroupBusinessCard,
    CommonPopover,
  },
  data() {
    return {
      // 投诉操作菜单
      // complaintOperatingMenu: [],
    };
  },
  props: {
    // 群组信息
    groupInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 展示操作气泡窗
    showPopover: {
      type: Boolean,
      default: false,
    },
    // 气泡弹窗菜单数据
    operatingMenu: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 展示底部
    showFooter: {
      type: Boolean,
      default: true,
    },
    searchPageKey: {
      type: String,
      default: "",
    },
  },
  computed: {
    complaintOperatingMenu() {
      let complaintOperatingMenu = [];

      // 不是是管理员，不是自己的群显示投诉
      if (
        this.$store.getters.userInfo.manager_role != 1 &&
        !this.showPopoverForGroupOwner
      ) {
        complaintOperatingMenu.push({
          iconClassName: "iconfont_Me icon-a-zu5510",
          operatingFn: this.complaintConfirm,
          tooltipContent: this.$t("complaint"),
        });
      }

      //是管理员，不是自己的群，走原因删除
      if (this.$store.getters.userInfo.manager_role == 1) {
        complaintOperatingMenu.push({
          iconClassName: "icon-delet",
          operatingFn: this.deleteConfirm,
          tooltipContent: this.$t("delete"),
        });
      }

      return complaintOperatingMenu;
    },
    // 名片信息集合
    groupBusinessCardInfo() {
      // 处理名片标签数组
      let tags = [];
      if (this.groupInfo.groupTag && this.groupInfo.groupTag !== "") {
        tags = this.groupInfo.groupTag.split(",");
      }
      // 这里的判断都是为了兼容
      if (this.groupInfo.content && this.groupInfo.content !== "") {
        this.groupInfo.content = this.groupInfo.content
          .replace(/<[^>]+>/g, "")
          .replace("&nbsp;", "");
      } else if (this.groupInfo.groupInfo && this.groupInfo.groupInfo !== "") {
        this.groupInfo.content = this.groupInfo.groupInfo
          .replace(/<[^>]+>/g, "")
          .replace("&nbsp;", "");
      }
      return {
        groupName: this.groupInfo.groupName,
        groupId: this.groupInfo.groupId,
        contentPic: this.groupInfo.groupHeader,
        groupOwnerId: this.groupInfo.groupOwnerId,
        tags: tags,
        content: this.groupInfo.content,
        accessPattern: this.groupInfo.accessPattern,
      };
    },
    // 只有群的群主才可以展示操作窗
    showPopoverForGroupOwner() {
      let showPopoverForGroupOwner = false;
      if (
        this.showPopover &&
        this.$store.getters.userInfo &&
        this.$store.getters.userInfo.id &&
        this.groupInfo.groupOwnerId === this.$store.getters.userInfo.id
      ) {
        showPopoverForGroupOwner = true;
      }
      return showPopoverForGroupOwner;
    },
    // 底部信息集合
    footerInfo() {
      return {
        likeStatus: this.groupInfo.likeStatus,
        praiseCount: this.groupInfo.likeCount,
        replyCount: this.groupInfo.commentCount,
        type: 4,
        id: this.groupInfo.groupId,
        imDisplayName: this.groupInfo.groupName,
        imAvatar: this.groupInfo.groupHeader,
        imIsGroup: true,
      };
    },
  },
  methods: {
    // 选择操作菜单的选项
    selectThisMenuItem(index) {
      if (this.operatingMenu.length !== 0) {
        this.operatingMenu[index].operatingFn(this.groupInfo);
      }
    },
    selectComplaintMenuItem(index) {
      this.complaintOperatingMenu[index].operatingFn(this.groupInfo);
    },
    complaintConfirm() {
      //投诉处理
      this.$store.commit("setComplaintType", 4);
      this.$store.commit("setComplaintId", this.groupInfo.groupId);
      this.$store.commit("setGroupInfo", this.groupInfo);
      this.$store.commit("setComplaintDialogVisible", true);
    },
    deleteConfirm() {
      this.$store.commit("setDeleteType", "group");
      this.$store.commit("setDeleteGroupId", this.groupInfo.groupId);
      this.$store.commit("setDeleteDialogVisible", true);
    },
  },
};
</script>
<style lang="stylus" scoped>
.informationArea {
  width: 100%;
  box-sizing: border-box;
  padding: 16px 16px 0 16px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px 0px rgba(204, 204, 204, 0.16);

  .groupCard {
    cursor: pointer;
    min-height: 266px;
  }

  .areaFooter {
    margin-top: 10px;
  }

  .commonPopoverStyle {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 2;

    .commonPopoverButton {
      width: 100%;
      height: 100%;
      width: 24px;
      height: 24px;
      cursor: pointer;
      border-radius: 4px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
