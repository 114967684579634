import http from "@/utils/http";
// 获取收藏夹列表
export function getFavoritesListData(params) {
  return http({
    baseURL: "/",
    method: "get",
    url: `/rest/v5/bookmark/getList`,
    withCredentials: false,
  });
}
// 添加新的收藏夹
export function addNewFavorites(params) {
  return http({
    baseURL: "/",
    method: "get",
    url: `/rest/v5/bookmark/add`,
    withCredentials: false,
    params: params
  });
}
// 编辑收藏夹名称
export function editFavoritesName(id,params) {
  return http({
    baseURL: "/",
    method: "get",
    url: `/rest/v5/bookmark/name/update/${id}`,
    withCredentials: false,
    params: params
  });
}
// 删除收藏夹
export function deleteFavorites(id) {
  return http({
    baseURL: "/",
    method: "get",
    url: `/rest/v5/bookmark/delete/${id}`,
    withCredentials: false,
  });
}
// 统计收藏夹数量
export function getFavoritesCount() {
  return http({
    baseURL: "/",
    method: "get",
    url: `/rest/v5/bookmark/count`,
    withCredentials: false,
  });
}
// 发起收藏请求
export function startCollection(dataId,params) {
  return http({
    baseURL: "/",
    method: "get",
    url: `/rest/v5/collect/${dataId}`,
    withCredentials: false,
    params: params
  });
}
// 发起取消收藏请求
export function startUnfavorite(dataId) {
  return http({
    baseURL: "/",
    method: "get",
    url: `/rest/v5/collect/cancel/${dataId}`,
    withCredentials: false,
  });
}
// 移动收藏数据
export function movefavorites(collectId,params) {
  return http({
    baseURL: "/",
    method: "get",
    url: `/rest/v5/collect/move/${collectId}`,
    withCredentials: false,
    params: params
  });
}
// 获取收藏数据
export function getCollectList(params) {
  return http({
    baseURL: "/",
    method: "get",
    url: `/rest/v5/collect/data/getList`,
    withCredentials: false,
    params: params
  });
}