var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "groupBusinessCardContent",
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.jumpToDetails.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "el-image",
        {
          staticClass: "avatarForGroup",
          attrs: { src: _vm.formartImageUrl(_vm.contentPic), fit: "cover" }
        },
        [
          _c(
            "div",
            {
              staticClass: "imageLoadError",
              staticStyle: { width: "100%", height: "100%" },
              attrs: { slot: "error" },
              slot: "error"
            },
            [_c("i", { staticClass: "thumbImg noimg" }, [_vm._v("Melinked")])]
          )
        ]
      ),
      _c("div", { staticClass: "groupName", attrs: { title: _vm.groupName } }, [
        _c("div", {
          staticClass: "groupNameInner",
          domProps: {
            innerHTML: _vm._s(
              _vm.highlightText(_vm.searchPageKey, _vm.groupName)
            )
          }
        }),
        _vm.isGroupOwner
          ? _c("span", {
              staticClass: "iconfont_Me iconStyle icon-qunzhu",
              style: { color: "#33CC66" }
            })
          : _vm._e(),
        _vm.accessPattern != 3
          ? _c("span", {
              staticClass: "iconfont_Me iconStyle",
              class:
                _vm.accessPattern == 1 ? "icon-open-fill" : "icon-member-fill",
              style: { color: _vm.accessPattern == 1 ? "#333333" : "#FFC526" },
              attrs: {
                title:
                  _vm.accessPattern == 1
                    ? `${_vm.$t("publicGroup")}: ${_vm.$t(
                        "publicGroupDescription"
                      )}`
                    : `${_vm.$t("memberGroup")}: ${_vm.$t(
                        "memberGroupDescription"
                      )}`
              }
            })
          : _vm._e()
      ]),
      _c("div", {
        staticClass: "groupContent",
        domProps: {
          innerHTML: _vm._s(_vm.highlightText(_vm.searchPageKey, _vm.content))
        }
      }),
      _vm.tags && _vm.tags.length != 0
        ? _c(
            "div",
            { staticClass: "groupLabelBox" },
            _vm._l(_vm.tags, function(item, index) {
              return _c("Label", {
                key: index + item,
                attrs: { labelContent: item, searchPageKey: _vm.searchPageKey }
              })
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }