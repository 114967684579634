<template>
  <div class="groupBusinessCardContent" @click.stop="jumpToDetails">
    <el-image
      :src="formartImageUrl(contentPic)"
      class="avatarForGroup"
      fit="cover"
    >
      <div slot="error" class="imageLoadError" style="width:100%;height:100%">
        <i class="thumbImg noimg">Melinked</i>
      </div>
    </el-image>
    <div class="groupName" :title="groupName">
      <div
        class="groupNameInner"
        v-html="highlightText(searchPageKey, groupName)"
      ></div>
      <span
        v-if="isGroupOwner"
        class="iconfont_Me iconStyle icon-qunzhu"
        :style="{ color: '#33CC66' }"
      ></span>
      <span
        v-if="accessPattern != 3"
        class="iconfont_Me iconStyle"
        :class="accessPattern == 1 ? 'icon-open-fill' : 'icon-member-fill'"
        :style="{ color: accessPattern == 1 ? '#333333' : '#FFC526' }"
        :title="
          accessPattern == 1
            ? `${$t('publicGroup')}: ${$t('publicGroupDescription')}`
            : `${$t('memberGroup')}: ${$t('memberGroupDescription')}`
        "
      ></span>
    </div>
    <div
      class="groupContent"
      v-html="highlightText(searchPageKey, content)"
    ></div>
    <div class="groupLabelBox" v-if="tags && tags.length != 0">
      <Label
        v-for="(item, index) in tags"
        :key="index + item"
        :labelContent="item"
        :searchPageKey="searchPageKey"
      ></Label>
    </div>
  </div>
</template>
<script>
import Label from "../label";
export default {
  components: {
    Label,
  },
  props: {
    groupBusinessCardInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 高亮显示
    searchPageKey: {
      type: String,
      default: "",
    },
  },
  computed: {
    // 群组名称
    groupName() {
      return this.groupBusinessCardInfo.groupName;
    },
    // 群组ID
    groupId() {
      return this.groupBusinessCardInfo.groupId;
    },
    // 群类型
    accessPattern() {
      return this.groupBusinessCardInfo.accessPattern;
    },
    // 群组封面图
    contentPic() {
      return this.groupBusinessCardInfo.contentPic != ""
        ? this.formartImageUrl(this.groupBusinessCardInfo.contentPic)
        : this.groupBusinessCardInfo.contentPic;
    },
    // 群内容
    content() {
      return this.groupBusinessCardInfo.content;
    },
    // 是否是群主
    isGroupOwner() {
      let isGroupOwner = false;
      if (
        this.$store.getters.userInfo &&
        this.$store.getters.userInfo.id &&
        this.groupBusinessCardInfo.groupOwnerId ===
          this.$store.getters.userInfo.id
      ) {
        isGroupOwner = true;
      }
      return isGroupOwner;
    },
    // 群标签
    tags() {
      return this.groupBusinessCardInfo.tags;
    },
  },
  methods: {
    // 跳转到详情页面
    jumpToDetails() {
      this.routeJump({
        name: "groupCenter",
        params: { id: this.groupId },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.groupBusinessCardContent
  width 100%;
  min-height 0;
  overflow hidden;
  .avatarForGroup
    width 100%;
    height 126px;
    border-radius 6px;
    .imageLoadError
      .thumbImg
        width 100%;
        height 100%;
        user-select none;
        display inline-block;
        background: #eee;
        font-style: normal;
        color: #ddd;
        display flex;
        align-items center;
        justify-content center;
        font-weight: 800;
        font-size: 30px;
  .groupName
    padding-top 15px;
    font-size 14px;
    font-weight bold;
    line-height 18px;
    height 33px;
    text-align center;
    display flex;
    align-items center;
    justify-content: center;
    .groupNameInner
      height 100%;
      overflow hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      white-space:normal;
      margin-right 5px;
    .iconStyle
      font-size 18px;
      margin-right 5px;
  .groupContent
    padding-top 10px;
    font-size 12px;
    line-height 18px;
    height 46px;
    text-align center;
    color #666666;
    overflow hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space:normal;
  .groupLabelBox
    width 100%;
    padding-top 15px;
    text-align center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-height 36px;
</style>
<style lang="stylus">
.avatarForGroup
  &:hover
    .el-image__inner
      transform scale(1.1) !important;
  .el-image__inner
    transform scale(1);
    transition transform .3s;
</style>
