<template>
  <div class="footerStyle" v-loading="likeStatusLoading">
    <div class="theFirstTypeStyle" @click="changelikeStatus">
      <span class="iconfont_Me iconStyle" :class="footerInfo.likeStatus?'icon-fabulous-fill':'icon-fabulous'" :style="{color:footerInfo.likeStatus?'#FC4C4C':''}"></span>
      <span class="countSpan">{{footerInfo.praiseCount}}</span>
    </div>
    <div class="theFirstTypeStyle" @click.stop="jumpToDetails">
      <span class="iconfont_Me icon-edit1 iconStyle"></span>
      <span class="countSpan">{{footerInfo.replyCount}}</span>
    </div>
    <div class="theSecondTypeStyle commomButtonHover" @click.stop="startChatting" v-if="!footerInfo.imIsGroup">
      <span class="iconfont_Me icon-information iconStyle"></span>
    </div>
    <SharePopover
       v-else
      :shareConfig="shareConfig"
      @shareForMelinked="shareForMelinked"
    >
      <div class="theSecondTypeStyle commomButtonHover">
        <span class="iconfont_Me icon-share iconStyle"></span>
      </div>
    </SharePopover>
  </div>
</template>
<script>
import { changelikeStatus } from "@/api/newVersion";
import SharePopover from "../sharePopover";
export default {
  props: {
    footerInfo: {
      type: Object,
      default: ()=>{
        return {
          // 你是否点赞过这个用户
          likeStatus: false,
          // 这个用户的点赞数
          praiseCount: 0,
          // 这个用户的回复数
          replyCount: 0,
          // 这里用于点赞类型标记（1:service(服务)，2:jobs(需求),3:people(个人)，4:group(群组), 5:enterprise(企业)）
          type: 1,
          // 这个组件对应的ID
          id: 0,
          // IM的昵称
          imDisplayName: '',
          // IM的头像
          imAvatar: '',
          // 是否是群聊
          imIsGroup: false,
        }
      }
    }
  },
  components: {
    SharePopover
  },
  data() {
    return {
      // 点赞加载loading
      likeStatusLoading: false,
      shareConfig: {
        // 分享的内容类型(1:帖子,3:用户,4:群)
        share_type: 4,
        // 分享的内容Id
        share_id: this.footerInfo.id,
      }
    }
  },
  methods: {
    // 分享群到melinked
    shareForMelinked(shareData) {
      // 重置分享信息
      let sharingDataInfo = {
        // 分享的标题
        title: shareData.shareTitle,
        // 分享展示图片
        picurl: shareData.shareImageSrc,
        // 分享展示内容
        briefInfo: shareData.summary,
        // 分享的类型（个人：people；群组：group；工作：job；帖子：service；公司：company）
        serviceType: 'group',
        // 分享对象的唯一ID
        uuid: this.footerInfo.id
      }
      this.$store.dispatch('commitOpenSharePopup',sharingDataInfo)
    },
    // 开始单人聊天
    async startChatting() {
      if(!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.chatWithAuthor(
        { thumb: this.footerInfo.imAvatar, name: this.footerInfo.imDisplayName, id: this.footerInfo.id },
        'user'
      )
    },
    // 跳转到详情页面评论
    jumpToDetails() {
      if(this.footerInfo.imIsGroup) {
        this.routeJump({
          name: "groupCenter",
          params: { id: this.footerInfo.id},
        });
      } else {
        this.routeJump({ name: 'personalCenter', params: { id: this.footerInfo.id } })
      }
    },
    // 改变喜欢状态
    async changelikeStatus() {
      if(!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let params = {
        userId: this.$store.getters.userInfo.id,
        language: localStorage.getItem("langCode"),
        action: !this.footerInfo.likeStatus,
        type: this.footerInfo.type
      };
      this.likeStatusLoading = true;
      let result = await changelikeStatus(this.footerInfo.id,params);
      this.likeStatusLoading = false;
      if(result.code === 201) {
        this.footerInfo.likeStatus = !this.footerInfo.likeStatus
        this.footerInfo.likeStatus?this.footerInfo.praiseCount++:this.footerInfo.praiseCount--;
        // 将点赞结果存入状态管理器中
        let likedRecord = {
          likeItemId: this.footerInfo.id,
          likeCount: this.footerInfo.praiseCount,
          likeStatus: this.footerInfo.likeStatus
        }
        this.$store.dispatch("commitSetAlreadyLikedRecords",likedRecord);
      } else {
        this.$message({
          type: "error",
          message: result.message
        })
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  .footerStyle
    width 100%;
    height 40px;
    margin-bottom 10px;
    display flex;
    align-items center;
    justify-content center;
    .theFirstTypeStyle
      width 80px;
      height 40px;
      background #F7F7F7;
      cursor pointer;
      display flex;
      align-items center;
      justify-content center;
      font-size 12px;
      color #8F8F8F;
      .iconStyle
        font-size 24px;
        color #666666;
        transition color .3s;
        &:hover
          color #333333;
      .countSpan
        display inline-block;
        font-size 12px;
        color #8F8F8F;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      &:nth-child(1)
        border-radius 20px 0px 0px 20px;
        margin-right 2px;
      &:nth-child(2)
        border-radius 0px 20px 20px 0px;
        margin-right 10px;
    .theSecondTypeStyle
      width 40px;
      height 40px;
      cursor pointer;
      border-radius 50%;
      user-select none;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        font-size 24px;
        color #FFFFFF

</style>
<style lang="stylus">
  .footerStyle
    .el-loading-spinner 
      margin-top: -10px;
      .circular
        width 20px;
        height 20px;

</style>