var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.likeStatusLoading,
          expression: "likeStatusLoading"
        }
      ],
      staticClass: "footerStyle"
    },
    [
      _c(
        "div",
        {
          staticClass: "theFirstTypeStyle",
          on: { click: _vm.changelikeStatus }
        },
        [
          _c("span", {
            staticClass: "iconfont_Me iconStyle",
            class: _vm.footerInfo.likeStatus
              ? "icon-fabulous-fill"
              : "icon-fabulous",
            style: { color: _vm.footerInfo.likeStatus ? "#FC4C4C" : "" }
          }),
          _c("span", { staticClass: "countSpan" }, [
            _vm._v(_vm._s(_vm.footerInfo.praiseCount))
          ])
        ]
      ),
      _c(
        "div",
        {
          staticClass: "theFirstTypeStyle",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.jumpToDetails.apply(null, arguments)
            }
          }
        },
        [
          _c("span", { staticClass: "iconfont_Me icon-edit1 iconStyle" }),
          _c("span", { staticClass: "countSpan" }, [
            _vm._v(_vm._s(_vm.footerInfo.replyCount))
          ])
        ]
      ),
      !_vm.footerInfo.imIsGroup
        ? _c(
            "div",
            {
              staticClass: "theSecondTypeStyle commomButtonHover",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.startChatting.apply(null, arguments)
                }
              }
            },
            [
              _c("span", {
                staticClass: "iconfont_Me icon-information iconStyle"
              })
            ]
          )
        : _c(
            "SharePopover",
            {
              attrs: { shareConfig: _vm.shareConfig },
              on: { shareForMelinked: _vm.shareForMelinked }
            },
            [
              _c(
                "div",
                { staticClass: "theSecondTypeStyle commomButtonHover" },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-share iconStyle"
                  })
                ]
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }